<template>
  <div class="calendar-match" ref="calendarMatch">
    <div class="date" :style="dateStyles">
      <span class="date__dayofweek">{{ formatedDate.dayOfWeek }}</span>
      <span class="date__day">{{ date.getUTCDate() }}</span>
      <span class="date__month">{{ formatedDate.month }}</span>
    </div>
    <div class="game" :class="`game--${statusOptions[isLive ? 1 : 2]}`" :style="landingStyles.elementHeight">
      <div class="game__container">
        <div class="game__head">
          <div v-if="isLive" class="game__live-tag">
            <span>• </span>
            <span>En vivo</span>
          </div>
          <div v-else-if="gameStatus === 4" class="game__head__time">{{ time }} HRS</div>
          <div v-else class="game__head__finished">Finalizado</div>
        </div>
        <div
          :class="{
            game__teams: getPremiumAccount,
            'game__teams--live': !getPremiumAccount,
            top: !getPremiumAccount || !probabilities || !predictions,
          }"
          :style="{
            'margin-top': isPostMatch || isLive || !probabilities ? '0.8rem' : '',
            'margin-top': isLanding ? '0' : '',
          }"
        >
          <div class="game__teams-left">
            <span class="game__teams-left__name"
              ><span>{{ teamLeft.colloquial_name }}</span></span
            >
            <img
              v-if="!isPostMatch"
              :class="{
                'game__teams-logo': getPremiumAccount && predictions,
                'game__teams-logo2': !getPremiumAccount && !predictions,
                'game__teams-logo3': !getPremiumAccount,
              }"
              :src="'https://az755631.vo.msecnd.net/teams-80/' + teamLeft.team_id + '.png'"
              alt="logo del equipo 1"
            />

            <div v-else-if="isPostMatch" class="game__teams-logo--live">
              <img
                class="game__teams-logo2"
                :src="'https://az755631.vo.msecnd.net/teams-80/' + teamLeft.team_id + '.png'"
                alt="logo del equipo 1"
              />
              <!-- <div class="game__score">{{ score[0] }}</div> -->
            </div>
            <div v-else class="game__teams-logo--live">
              <img
                class="game__teams-logo"
                :src="'https://az755631.vo.msecnd.net/teams-80/' + teamLeft.team_id + '.png'"
                alt="logo del equipo 1"
              />
              <!-- <div class="game__score">{{ score[0] }}</div> -->
            </div>
          </div>
          <div>
            <template v-if="isPostMatch || isLive">
              <span class="game__scorenew">{{ score[0] }} · {{ score[1] }} </span></template
            >
            <template v-else><span class="game__vs">VS</span></template>
          </div>
          <div class="game__teams-right">
            <img
              v-if="!isPostMatch"
              :class="{
                'game__teams-logo': getPremiumAccount && predictions,
                'game__teams-logo2': !getPremiumAccount && !predictions,
                'game__teams-logo3': !getPremiumAccount,
              }"
              :src="'https://az755631.vo.msecnd.net/teams-80/' + teamRight.team_id + '.png'"
              alt="logo del equipo 1"
            />
            <div v-else-if="isPostMatch" class="game__teams-logo--live">
              <img
                class="game__teams-logo2"
                :src="'https://az755631.vo.msecnd.net/teams-80/' + teamRight.team_id + '.png'"
                alt="logo del equipo 2"
              />
              <!-- <div class="game__score">{{ score[1] }}</div> -->
            </div>
            <div v-else class="game__teams-logo--live">
              <img
                class="game__teams-logo"
                :src="'https://az755631.vo.msecnd.net/teams-80/' + teamRight.team_id + '.png'"
                alt="logo del equipo 2"
              />
              <!-- <div class="game__score">{{ score[1] }}</div> -->
            </div>
            <span class="game__teams-right__name"
              ><span>{{ teamRight.colloquial_name }}</span></span
            >
          </div>
        </div>
        <div class="game__stats">
          <template v-if="!isPostMatch">
            <template v-if="getPremiumAccount && probabilities">
              <StatsContainer
                v-if="!isLive"
                :titles="probsTitles"
                :contents="probsContents"
                :color="color"
                :background="background"
              />
            </template>
            <!-- <div class="game__stats-xg " :style="isLive || !probabilities ? 'margin-top: 0.8rem' : ''">
              <template v-if="getPremiumAccount && predictions">
                <StatsContainer :titles="xgTitles" :contents="leftXgConent" :color="color" :background="background" />
                <StatsContainer
                  :titles="xgTitlesReversed"
                  :contents="rightXgConent"
                  :color="color"
                  :background="background"
                />
              </template>
            </div> -->
          </template>
        </div>
      </div>
      <div class="actions" @click="onClickViewStats">
        <div class="buttom-simple" @click="$emit('action')">Ver <img src="/images/arrow-green.png" /></div>
      </div>
    </div>
  </div>
</template>

<script>
import StatsContainer from '@/components/Sections/Calendar/StatsContainer';
import { mapMutations, mapGetters } from 'vuex';
export default {
  name: 'CalendarMatch',
  components: { StatsContainer },
  props: {
    isPremiumUser: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '#fff',
    },
    predictions: {
      type: Boolean,
      default: true,
    },
    background: {
      type: String,
      default: '#DBE3E6',
    },
    backgroundLive: {
      type: String,
      default: '#77a405',
    },
    gridNum: {
      type: Number,
      required: true,
    },
    isGameLive: {
      type: Boolean,
      default: false,
    },
    time: {
      type: String,
      default: '20:30',
    },
    date: {
      type: Date,
      default: () => new Date(),
    },
    teamRight: {
      type: Object,
      required: true,
    },
    teamLeft: {
      type: Object,
      required: true,
    },
    gameStatus: {
      type: Number,
      required: true,
    },
    score: {
      type: Array,
      default: () => [],
    },
    percentages: {
      type: Array,
      default: () => [],
    },
    homeGoals: {
      type: Array,
      default: () => [],
    },
    visitingGoals: {
      type: Array,
      default: () => [],
    },
    probabilities: {
      type: [Object, null],
      default: () => null,
    },
    buttonText: {
      type: String,
      default: 'Estadísticas',
    },
    isLanding: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      statusOptions: {
        0: 'pending',
        1: 'live',
        2: 'finished',
      },
      probsTitles: ['Gana:', 'Empate:', 'Gana:'],
      xgTitles: ['Goles', 'xG'],
    };
  },
  computed: {
    ...mapGetters('loginGeneral', ['getPremiumAccount']),
    xgTitlesReversed() {
      return this.xgTitles.map(i => i).reverse();
    },
    leftXgConent() {
      return this.homeGoals;
    },
    rightXgConent() {
      return this.visitingGoals.map(i => i).reverse();
    },
    dateStyles() {
      return {
        color: this.color,
        background: this.isLive ? '#77a405' : '#132839',
        height: this.isLanding ? '110px' : '113px',
      };
    },
    landingStyles() {
      return {
        elementHeight: {
          height: this.isLanding ? '109px' : '113px',
        },
      };
    },
    buttonStyles() {
      return {
        color: this.color,
        background: this.background,
      };
    },
    formatedDate() {
      const dayOfWeek = this.date
        .toLocaleDateString('es-ES', { weekday: 'long', timeZone: 'UTC' })
        .split(', ')[0]
        .substring(0, 3);

      const month = new Intl.DateTimeFormat('es-ES', { month: 'short', timeZone: 'UTC' })
        .format(this.date)
        .split('.')[0];

      return { month, dayOfWeek };
    },
    probsContents() {
      return this.percentages.map(p => `${p}%`);
    },
    isLive() {
      return this.gameStatus === 4 && this.isGameLive;
    },
    isPostMatch() {
      return this.gameStatus === 1 || this.gameStatus === 0;
    },
  },
  methods: {
    ...mapMutations('general', ['setPreviewSectionAnalytics']),
    onClickViewStats() {
      this.setPreviewSectionAnalytics('Calendar');
    },
  },
};
</script>

<style lang="scss" scoped>
.top {
  margin-top: 16px;
  @media screen and (max-width: 350px) {
    margin-top: 24px;
  }
}
.buttom-simple {
  width: 75px;
  height: 30px;
  border-radius: 6px;
  background-color: #132739;
  font-family: 'Avenir-Demi';
  font-size: 17px;
  font-weight: normal;
  display: inline-flex;
  margin-right: 16px;
  justify-content: center;
  align-content: center;
  overflow: none;
  align-items: center;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.34px;
  text-align: center;
  color: #cbee6d;
  img {
    margin-left: 5px;
    @media screen and (max-width: 430px) {
      margin-left: 0px;
      margin-right: 4px;
    }
  }
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  @media screen and (max-width: 570px) {
    width: 65px;
  }
  @media screen and (max-width: 430px) {
    justify-content: space-between;
    width: 50px;
    padding-left: 5px;
    margin-right: 8px;
  }
  @media screen and (max-width: 430px) {
    margin-right: 4px;
  }
}
.calendar-match {
  width: 100%;
  box-sizing: content-box;
  display: flex;
  font-family: 'Avenir-Regular';
  & .date,
  & .game {
    height: 113px;
    border-radius: 4px;
  }

  & .date {
    text-align: center;
    width: 15%;
    min-width: 44px;
    margin-right: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    & * {
      cursor: default;
    }

    & > span {
      letter-spacing: 0.06rem;
      line-height: 1.6rem;
    }

    &__dayofweek,
    &__month {
      text-transform: uppercase;
      font-family: 'Avenir-Demi';
      font-size: 0.75rem;
      // letter-spacing: 0.24rem;
    }

    &__day {
      font-family: 'Circular-Std';
      font-size: 2.1rem;
      letter-spacing: -0.07rem !important;
    }
  }

  & .game {
    width: 82%;
    display: flex;
    border: solid 0.8px #d2d2d2;
    justify-content: center;
    // background: yellow;
    &--finished {
      width: 82%;
      // &__scorenew {
      //   // background: green;
      // }
    }

    &__score {
      font-family: 'Circular-Std-Bold';
      font-size: 1.5rem;
      color: #132839 !important;
      // background: red;
    }

    &__container {
      width: 72.5%;
      margin: 0 0.7rem;
      // background: paleturquoise;

      @media screen and (max-width: 430px) {
        width: 96%;
        // background: purple;
        margin: 0 0.1rem;
      }
      & * {
        cursor: default;
      }
    }

    &__head {
      // background: red;
      width: 100%;
      height: 18px;
      margin-top: 6px;
      margin-bottom: 5px;
      &__time {
        display: block;
        font-size: 0.875rem;
        font-weight: 600;
        font-family: 'Avenir-Demi';
        margin-top: 1px;
        color: #000;
        letter-spacing: -0.28px;
      }
      &__finished {
        font-size: 14px;
        font-weight: normal;
        font-family: 'Avenir-Demi';
        font-stretch: normal;
        font-style: normal;
        margin-top: 3px;
        line-height: 18px;
        letter-spacing: normal;
        text-align: center;
        letter-spacing: -0.28px;
        color: #000;
        // @media screen and (max-width: 400px) {
        //   font-size: 11px;
        // }
      }
    }
    &__scorenew {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      // background: green;
      width: 65px;
      height: 25px;
      margin-top: 3px;
      font-size: 27px;
      font-family: 'Circular-Std-Bold';
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.85;
      letter-spacing: -0.54px;
      text-align: center;
      color: #000;
      @media screen and (max-width: 1328px) {
        margin-left: 30px !important;
        margin-right: 30px !important;
      }
      @media screen and (max-width: 768px) {
        margin-left: 10px !important;
        margin-right: 10px !important;
      }
      @media screen and (max-width: 680px) {
        margin-left: 0px !important;
        margin-right: 0px !important;
      }
      @media screen and (max-width: 645px) {
        width: 50px;
        font-size: 24px;
      }
      @media screen and (max-width: 470px) {
        width: 40px;
        font-size: 18px;
      }
    }
    &__vs {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      // background: green;
      width: 65px;
      height: 25px;
      font-size: 27px;
      font-family: 'Circular-Std-Bold';
      font-weight: bold;
      font-stretch: normal;
      margin-top: 5px;
      font-style: normal;
      line-height: 0.85;
      letter-spacing: -0.54px;
      text-align: center;
      color: #000;
      @media screen and (max-width: 1328px) {
        margin-left: 30px !important;
        margin-right: 30px !important;
      }
      @media screen and (max-width: 768px) {
        margin-left: 10px !important;
        margin-right: 10px !important;
      }
      @media screen and (max-width: 680px) {
        margin-left: 0px !important;
        margin-right: 0px !important;
      }
      @media screen and (max-width: 645px) {
        width: 50px;
        font-size: 24px;
      }
      @media screen and (max-width: 470px) {
        width: 40px;
        font-size: 20px;
      }
    }
    // &__vs {
    //   background: rgb(236, 240, 23);
    // }
    &__live-tag {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      margin-top: 2px;
      height: 15px;
      width: 4.063rem;
      border-radius: 3px;
      font-size: 0.625rem;
      line-height: 1.75em;
      font-weight: normal;
      font-family: 'Avenir-Pro-Medium';
      background: #3c3c3c;

      & span:first-child {
        color: #77a405;
        font-size: 1.5em;
        margin-right: 3px;
      }

      & span:last-child {
        color: #fff;
      }
    }

    &__teams,
    &__teams--live {
      display: inline-flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      // background: red;
      height: 50px;

      &-left,
      &-vs,
      &-vs2,
      &-vsg1,
      &-vsg2,
      &-vsg3,
      &-vsg4,
      &-right {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        color: #132839;
        width: 139px;
        height: 52px;
        // background: blue;
        @media screen and (max-width: 1328px) {
          width: auto;
        }
        &__name {
          width: 93px;
          height: 52px;

          // background: rgb(5, 175, 13);
          font-family: 'Avenir-Demi';
          font-size: 18px;
          font-weight: normal;
          display: inline-flex;
          justify-content: center;
          align-items: center;

          line-height: 0.95;

          letter-spacing: -0.38px;
          text-align: center;
          color: #132839;
          span {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
          @media screen and (max-width: 1328px) {
            width: 139px;
          }
          @media screen and (max-width: 860px) {
            width: 115px;
          }
          @media screen and (max-width: 780px) {
            width: 110px;
          }
          @media screen and (max-width: 768px) {
            width: 120px;
          }
          @media screen and (max-width: 730px) {
            width: 105px;
          }
          @media screen and (max-width: 645px) {
            font-size: 16px;
            width: 93px;
            // background: goldenrod;
          }
          @media screen and (max-width: 570px) {
            font-size: 15px;
            width: 80px;
            // background: goldenrod;
          }
          @media screen and (max-width: 530px) {
            font-size: 14px;
            width: 70px;
            // background: goldenrod;
          }
          @media screen and (max-width: 460px) {
            font-size: 12px;
            width: 58px;
          }
          @media screen and (max-width: 380px) {
            font-size: 10px;
            width: 48px;
          }
          @media screen and (max-width: 340px) {
            font-size: 8px;
            width: 40px;
          }
        }
      }

      &-vs2 {
        font-size: 0.8rem;
        font-family: 'Circular-Std-Book';
        margin: 0 1rem;
        color: #132839;
        @media screen and (max-width: 500px) {
          margin: 0 0.6rem;
        }
        @media screen and (max-width: 320px) {
          margin: 0 0.3rem;
        }
      }
      &-vsg1 {
        font-size: 0.8rem;
        font-family: 'Circular-Std-Book';
        margin: 0 1rem;
        color: #132839;
        @media screen and (max-width: 500px) {
          margin: 0 0.6rem;
        }
        @media screen and (max-width: 320px) {
          margin: 0 0.3rem;
        }
      }
      &-vsg2 {
        font-size: 0.8rem;
        font-family: 'Circular-Std-Book';
        margin: 0 0.4rem;
        color: #132839;
      }
      &-vsg3 {
        font-size: 0.8rem;
        font-family: 'Circular-Std-Book';
        margin: 0 0.4rem;
        color: #132839;
      }
      &-vsg4 {
        font-size: 0.8rem;
        font-family: 'Circular-Std-Book';
        margin: 0 1rem;
        color: #132839;
        @media screen and (max-width: 500px) {
          margin: 0 0.6rem;
        }
        @media screen and (max-width: 320px) {
          margin: 0 0.3rem;
        }
      }
      &-left,
      &-right {
        font-family: 'Circular-Std-Bold';
        font-size: 0.8125rem;
      }
      &-vs {
        font-family: 'Circular-Std-Book';
        margin: 0 1rem;
        font-size: 0.6875rem;
        @media screen and (max-width: 500px) {
          margin: 0 0.6rem;
        }
        @media screen and (max-width: 320px) {
          margin: 0 0.3rem;
        }
      }
      &-logo {
        height: 44px;
        // margin: auto 5px;
        // font-size: 1.2rem;
        // background: red;
        @media screen and (max-width: 645px) {
          height: 44px;
        }
        @media screen and (max-width: 530px) {
          height: 38px;
          margin-top: 3px;
        }
        @media screen and (max-width: 400px) {
          height: 32px;
          margin-top: 5px;
        }
        &--live {
          height: 30px;
        }
      }
      &-logo2 {
        height: 44px;
        // margin: auto 5px;
        // background: rgb(63, 185, 26);
        // font-size: 1.2rem;
        margin-top: -8px;
        @media screen and (max-width: 645px) {
          height: 44px;
        }
        @media screen and (max-width: 530px) {
          height: 38px;
          margin-top: 3px;
        }
        @media screen and (max-width: 400px) {
          height: 32px;
          margin-top: 5px;
        }
        &--live {
          height: 30px;
        }
      }
      &-logo3 {
        height: 44px;
        // margin: auto 5px;
        // background: rgb(63, 185, 26);
        // font-size: 1.2rem;
        // margin-top: -8px;
        @media screen and (max-width: 645px) {
          height: 44px;
        }
        @media screen and (max-width: 530px) {
          height: 38px;
          margin-top: 3px;
        }
        @media screen and (max-width: 400px) {
          height: 32px;
          margin-top: 5px;
        }
        &--live {
          height: 30px;
        }
      }

      &-acronym2 {
        font-size: 1rem;
        padding-left: 5px;
        padding-right: 5px;
        @media screen and (max-width: 350px) {
          font-size: 1rem;
        }
        visibility: hidden;
      }
      &-acronym4 {
        font-size: 0.4rem;
        visibility: hidden;
      }
      &-acronym3 {
        font-size: 0.9rem;
        visibility: hidden;
      }
      &-acronym1 {
        font-size: 0.9rem;
        visibility: hidden;
      }
    }

    // &__teams-scorenew {
    //   width: 65px;
    //   height: 25px;
    //   background: red !important;
    // }

    &__teams--live {
      height: max-content;
      .game__teams-logo {
        height: 48px;
        margin: auto;
        &--live {
          height: 45px;
          min-width: 45px;
          @media screen and (max-width: 470px) {
            min-width: auto;
          }
        }
      }
      .game__scorenew {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        // background: green;
        width: 65px;
        height: 25px;
        margin-top: 3px;
        font-size: 27px;
        font-family: 'Circular-Std-Bold';
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.85;
        letter-spacing: -0.54px;
        text-align: center;
        color: #000;
        @media screen and (max-width: 1328px) {
          margin-left: 30px !important;
          margin-right: 30px !important;
        }
        @media screen and (max-width: 768px) {
          margin-left: 10px !important;
          margin-right: 10px !important;
        }
        @media screen and (max-width: 680px) {
          margin-left: 0px !important;
          margin-right: 0px !important;
        }
        @media screen and (max-width: 645px) {
          width: 50px;
          font-size: 24px;
        }
        @media screen and (max-width: 470px) {
          width: 40px;
          font-size: 18px;
        }
      }
      .game__vs {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        // background: green;
        width: 65px;
        height: 25px;
        font-size: 27px;
        font-family: 'Circular-Std-Bold';
        font-weight: bold;
        font-stretch: normal;
        margin-top: 5px;
        font-style: normal;
        line-height: 0.85;
        letter-spacing: -0.54px;
        text-align: center;
        color: #000;
        @media screen and (max-width: 1328px) {
          margin-left: 30px !important;
          margin-right: 30px !important;
        }
        @media screen and (max-width: 768px) {
          margin-left: 10px !important;
          margin-right: 10px !important;
        }
        @media screen and (max-width: 680px) {
          margin-left: 0px !important;
          margin-right: 0px !important;
        }
        @media screen and (max-width: 645px) {
          width: 50px;
          font-size: 24px;
        }
        @media screen and (max-width: 470px) {
          width: 40px;
          font-size: 20px;
        }
      }
    }

    &__stats {
      margin: auto;
      &-xg {
        display: flex;
        justify-content: space-between;
        width: 100%;
        & > div {
          width: 48%;
        }
      }
    }
  }

  & .actions {
    height: 100%;
    display: flex;
    align-items: center;
    align-content: center;

    &__info-button {
      box-sizing: border-box;
      font-family: 'Circular-Std-Medium';
      font-size: 10px;
      color: #3a3a3a;
      letter-spacing: -0.05px;
      text-align: center;
      margin-top: 0.5rem;
      background: rgb(254, 208, 37);
      background: linear-gradient(180deg, rgba(254, 208, 37, 1) 0%, rgba(240, 178, 17, 1) 100%);
      width: 4.1rem;
      height: 1.9rem;
      line-height: 20px;
      box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.13);
      border-radius: 8px;
      padding: 0.4rem;
      border-radius: 7px;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.19);
      cursor: pointer;
      &.live {
        background-color: #77a405;
        color: #fff;
      }
    }
    &__info-button-1 {
      box-sizing: border-box;
      font-family: 'Circular-Std-Medium';
      font-size: 12px;
      color: #3a3a3a;
      letter-spacing: -0.05px;
      text-align: center;
      margin-top: 0.5rem;
      background: rgb(254, 208, 37);
      background: linear-gradient(180deg, rgba(254, 208, 37, 1) 0%, rgba(240, 178, 17, 1) 100%);
      width: 5rem;
      height: 1.9rem;
      line-height: 20px;
      box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.13);
      border-radius: 8px;
      padding: 0.4rem;
      border-radius: 7px;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.19);
      cursor: pointer;

      @media screen and (max-width: 510px) {
        width: 3rem;
        font-size: 14px;
      }
      @media screen and (max-width: 380px) {
        width: 2.5rem;
        font-size: 13px;
      }
      @media screen and (max-width: 320px) {
        width: 2rem;
        font-size: 11px;
        margin-left: -7px;
      }
      &.live {
        background-color: #77a405;
        color: #fff;
      }
      &__text-mobile {
        display: none;
        @media screen and (max-width: 510px) {
          display: inline-block;
        }
      }
      &__text-stats {
        display: inline-block;
        @media screen and (max-width: 510px) {
          display: none;
        }
      }
    }
    &__info-button-2 {
      box-sizing: border-box;
      font-family: 'Circular-Std-Medium';
      font-size: 10px;
      color: #3a3a3a;
      letter-spacing: -0.05px;
      text-align: center;
      margin-left: -12px;
      margin-top: 0.5rem;
      background: rgb(254, 208, 37);
      background: linear-gradient(180deg, rgba(254, 208, 37, 1) 0%, rgba(240, 178, 17, 1) 100%);
      width: 4.2rem;
      height: 1.9rem;
      line-height: 20px;
      box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.13);
      border-radius: 8px;
      padding: 0.4rem;
      border-radius: 7px;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.19);
      cursor: pointer;
      @media screen and (max-width: 768px) {
        width: 3.7rem;
        font-size: 9px;
      }
      &.live {
        background-color: #77a405;
        color: #fff;
      }
      &__text-mobile {
        display: none;
      }
      &__text-stats {
        display: inline-block;
      }
    }
    &__info-button-3 {
      box-sizing: border-box;
      font-family: 'Circular-Std-Medium';
      font-size: 12px;
      color: #3a3a3a;
      letter-spacing: -0.05px;
      text-align: center;
      margin-left: -8px;
      margin-top: 0.5rem;
      background: rgb(254, 208, 37);
      background: linear-gradient(180deg, rgba(254, 208, 37, 1) 0%, rgba(240, 178, 17, 1) 100%);
      width: 3rem;
      height: 1.9rem;
      line-height: 20px;
      box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.13);
      border-radius: 8px;
      padding: 0.4rem;
      border-radius: 7px;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.19);
      cursor: pointer;
      &.live {
        background-color: #77a405;
        color: #fff;
      }
      &__text-mobile {
        display: none;
      }
      &__text-stats {
        display: inline-block;
      }
    }
    &__info-button-4 {
      box-sizing: border-box;
      font-family: 'Circular-Std-Medium';
      font-size: 12px;
      color: #3a3a3a;
      letter-spacing: -0.05px;
      text-align: center;
      margin-left: -8px;
      margin-top: 0.5rem;
      background: rgb(254, 208, 37);
      background: linear-gradient(180deg, rgba(254, 208, 37, 1) 0%, rgba(240, 178, 17, 1) 100%);
      width: 3rem;
      height: 1.9rem;
      line-height: 20px;
      box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.13);
      border-radius: 8px;
      padding: 0.4rem;
      border-radius: 7px;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.19);
      cursor: pointer;
      &.live {
        background-color: #77a405;
        color: #fff;
      }
      &__text-mobile {
        display: none;
      }
      &__text-stats {
        display: inline-block;
      }
    }
  }
}
</style>
