var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"calendarMatch",staticClass:"calendar-match"},[_c('div',{staticClass:"date",style:(_vm.dateStyles)},[_c('span',{staticClass:"date__dayofweek"},[_vm._v(_vm._s(_vm.formatedDate.dayOfWeek))]),_c('span',{staticClass:"date__day"},[_vm._v(_vm._s(_vm.date.getUTCDate()))]),_c('span',{staticClass:"date__month"},[_vm._v(_vm._s(_vm.formatedDate.month))])]),_c('div',{staticClass:"game",class:`game--${_vm.statusOptions[_vm.isLive ? 1 : 2]}`,style:(_vm.landingStyles.elementHeight)},[_c('div',{staticClass:"game__container"},[_c('div',{staticClass:"game__head"},[(_vm.isLive)?_c('div',{staticClass:"game__live-tag"},[_c('span',[_vm._v("• ")]),_c('span',[_vm._v("En vivo")])]):(_vm.gameStatus === 4)?_c('div',{staticClass:"game__head__time"},[_vm._v(_vm._s(_vm.time)+" HRS")]):_c('div',{staticClass:"game__head__finished"},[_vm._v("Finalizado")])]),_c('div',{class:{
            game__teams: _vm.getPremiumAccount,
            'game__teams--live': !_vm.getPremiumAccount,
            top: !_vm.getPremiumAccount || !_vm.probabilities || !_vm.predictions,
          },style:({
            'margin-top': _vm.isPostMatch || _vm.isLive || !_vm.probabilities ? '0.8rem' : '',
            'margin-top': _vm.isLanding ? '0' : '',
          })},[_c('div',{staticClass:"game__teams-left"},[_c('span',{staticClass:"game__teams-left__name"},[_c('span',[_vm._v(_vm._s(_vm.teamLeft.colloquial_name))])]),(!_vm.isPostMatch)?_c('img',{class:{
                'game__teams-logo': _vm.getPremiumAccount && _vm.predictions,
                'game__teams-logo2': !_vm.getPremiumAccount && !_vm.predictions,
                'game__teams-logo3': !_vm.getPremiumAccount,
              },attrs:{"src":'https://az755631.vo.msecnd.net/teams-80/' + _vm.teamLeft.team_id + '.png',"alt":"logo del equipo 1"}}):(_vm.isPostMatch)?_c('div',{staticClass:"game__teams-logo--live"},[_c('img',{staticClass:"game__teams-logo2",attrs:{"src":'https://az755631.vo.msecnd.net/teams-80/' + _vm.teamLeft.team_id + '.png',"alt":"logo del equipo 1"}})]):_c('div',{staticClass:"game__teams-logo--live"},[_c('img',{staticClass:"game__teams-logo",attrs:{"src":'https://az755631.vo.msecnd.net/teams-80/' + _vm.teamLeft.team_id + '.png',"alt":"logo del equipo 1"}})])]),_c('div',[(_vm.isPostMatch || _vm.isLive)?[_c('span',{staticClass:"game__scorenew"},[_vm._v(_vm._s(_vm.score[0])+" · "+_vm._s(_vm.score[1])+" ")])]:[_c('span',{staticClass:"game__vs"},[_vm._v("VS")])]],2),_c('div',{staticClass:"game__teams-right"},[(!_vm.isPostMatch)?_c('img',{class:{
                'game__teams-logo': _vm.getPremiumAccount && _vm.predictions,
                'game__teams-logo2': !_vm.getPremiumAccount && !_vm.predictions,
                'game__teams-logo3': !_vm.getPremiumAccount,
              },attrs:{"src":'https://az755631.vo.msecnd.net/teams-80/' + _vm.teamRight.team_id + '.png',"alt":"logo del equipo 1"}}):(_vm.isPostMatch)?_c('div',{staticClass:"game__teams-logo--live"},[_c('img',{staticClass:"game__teams-logo2",attrs:{"src":'https://az755631.vo.msecnd.net/teams-80/' + _vm.teamRight.team_id + '.png',"alt":"logo del equipo 2"}})]):_c('div',{staticClass:"game__teams-logo--live"},[_c('img',{staticClass:"game__teams-logo",attrs:{"src":'https://az755631.vo.msecnd.net/teams-80/' + _vm.teamRight.team_id + '.png',"alt":"logo del equipo 2"}})]),_c('span',{staticClass:"game__teams-right__name"},[_c('span',[_vm._v(_vm._s(_vm.teamRight.colloquial_name))])])])]),_c('div',{staticClass:"game__stats"},[(!_vm.isPostMatch)?[(_vm.getPremiumAccount && _vm.probabilities)?[(!_vm.isLive)?_c('StatsContainer',{attrs:{"titles":_vm.probsTitles,"contents":_vm.probsContents,"color":_vm.color,"background":_vm.background}}):_vm._e()]:_vm._e()]:_vm._e()],2)]),_c('div',{staticClass:"actions",on:{"click":_vm.onClickViewStats}},[_c('div',{staticClass:"buttom-simple",on:{"click":function($event){return _vm.$emit('action')}}},[_vm._v("Ver "),_c('img',{attrs:{"src":"/images/arrow-green.png"}})])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }