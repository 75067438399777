<template>
  <div class="stats-probs">
    <div class="stats-probs__container" v-for="(item, index) in items" :key="`sp-item-${index}`">
      <div class="title">
        <span>{{ item.title }}</span>
      </div>
      <div class="content" v-if="item.title != 'xG' && item.title != 'Goles'">
        <span>
          <template v-if="item.content == '0%'">
            -
          </template>
          <template v-else>
            {{ item.content }}
          </template>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'StatsContainer',
  props: {
    titles: {
      type: Array,
      required: true,
    },
    contents: {
      type: Array,
      required: true,
    },
    color: {
      type: String,
      default: '#fff',
    },
    background: {
      type: String,
      default: '#DBE3E6',
    },
  },
  computed: {
    items() {
      return this.titles.map((_, index) => {
        return {
          title: this.titles[index],
          content: this.contents[index],
        };
      });
    },
    titleStyles() {
      return {
        color: '#000000',
        background: '#DBE3E6',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.stats-probs {
  display: flex;
  justify-content: space-between;

  & .title,
  & .content {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 0.6rem;
    margin: 0px 0;
    line-height: 1.2;
    width: 60%;
    text-align: center;
    background-color: #e6eaef;
    text-transform: capitalize;
    @media screen and (max-width: 490px) {
      font-size: 0.5rem;
    }
    &:last-child {
      background: white;
      width: 40%;
      @media screen and (max-width: 490px) {
        font-size: 0.5rem;
      }
    }
  }

  // & .title {
  //   padding: 2px 4px;
  //   opacity: 0.85;
  // }
  & .content {
    font-family: 'Avenir-Pro-Bold';
    font-size: 0.7rem;
  }

  &__container {
    width: 30%;
    border: solid 1px #e6eaef;
    display: inline-flex;
    border-radius: 6px;
    margin-top: 5px;
    height: 18px;
    overflow: hidden;
    // &:first-child {
    //   & .title {
    //     // border-radius: 3px 0 0 3px;
    //     background: red;
    //   }
    // }
    // &:last-child {
    //   & .title {
    //     // border-radius: 0 3px 3px 0;
    //     background: yellowgreen;
    //   }
    // }
  }
}
</style>
